<template>
    <form-view 
        v-slot="{ model, onSuccess, onBack }"
        title="Новое задание"
        create
        action-one="task/one"
        :url-update="updateUrl"
        :url-back="backUrl"
        :fields="fields"
    >
        <task-form :model="prefillModel(model || {})" @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import TaskForm from '@/components/forms/TaskForm.vue'
export default {
    name: 'TaskCreate',
    components: { FormView, TaskForm },
    computed: {
        fields () {
            return [ 'year', 'subject', 'grade', 'option', 'task', 'themes', 'data', 'category', 'collection' ]
        },
        backUrl () {
            const urlSearchParams = new URLSearchParams(window.location.search)
            urlSearchParams.delete('base_id')
            return `/task?${decodeURIComponent(urlSearchParams.toString())}`
        },
        updateUrl () {
            const urlSearchParams = new URLSearchParams(window.location.search)
            urlSearchParams.delete('base_id')
            return `/task/update/:id?${decodeURIComponent(urlSearchParams.toString())}`
        }
    },
    methods: {
        prefillModel (model) {
            
            const urlSearchParams = new URLSearchParams(window.location.search)

            const subject = urlSearchParams.get('subject')
            const grade = urlSearchParams.get('grade')

            if (subject)
                model.subject = subject

            if (grade)
                model.grade = parseInt(grade)

            return model
        }
    }
}
</script>